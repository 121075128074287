import React, { useEffect } from 'react';

import { RootState } from '../../store';
import { Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';

import TicketControls from './VirtualTicketEditorControls';
import VirtualTicketBetRow from './VirtualTicketBetRow';
import { ReactComponent as InfoIcon } from '../../img/info-24px.svg';
import {
  CombiGroupBet,
  FEG_BetslipGroup,
  FEG_BetslipStake,
  SystemGroupBet,
  TicketGroup,
  TicketMessage,
} from '../../store/ticket/ticket-types';
import { Bet } from '../../store/offer/types';

const TicketEditor: React.FC<TicketEditorReduxProps> = (props) => {
  const {
    //oids,
    legs,
    //bets,
    groups,
    ticket_mode,
    messages,
    castig,
    miza,
    legal,
    totalOddString,
    totalOdd,
    shortCode,
    stake,
  } = props;
  useEffect(() => {
    console.log(legs);
  });

  if (legs.length === 0) {
    return (
      <div
        className='flex'
        style={{
          width: '100%',
          margin: 0,
          padding: 0,
          height: '200px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div className='flex flex-col items-center content-center'>
          <InfoIcon />
          <div className='text-13 semibold' style={{ color: '#85878B' }}>
            Biletul este gol
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className='ticket-body flex-shrink-0'>
        {ticket_mode !== 'GROUP_COMBI' && (
          <>
            {legs.map((leg, index) => (
              <VirtualTicketBetRow
                key={leg.legId}
                editable={true}
                mode={ticket_mode}
                roundedTop={index !== 0}
                leg={leg}
                groups={groups}
              />
            ))}
          </>
        )}
        {ticket_mode === 'GROUP_COMBI' && (
          <>
            {Object.values(groups).map((group) => (
              <div key={group.name} className='mb-24'>
                <div
                  className='h-36 lh-36 text-11 text-white semibold flex mb-1'
                  style={{ marginRight: 54 }}
                >
                  <div className='inline-flex space-between flex-grow bg-gray-gradient rounded-t mr-1 px-12 box-border'>
                    <span>
                      GRUP <span className='text-yellow'>{group.name}</span>
                    </span>
                    <span>
                      {Object.values(groups).length}{' '}
                      {Object.values(groups).length !== 1 ? 'Pariuri' : 'Pariu'}
                    </span>
                  </div>
                  <div
                    className='inline-flex space-between bg-gray-gradient rounded-t px-12 box-border'
                    style={{ width: 111 }}
                  >
                    <span>Cota</span>
                    <span className='text-yellow'>{group.oddsPlaced}</span>
                  </div>
                </div>

                {Object.values(group.legs).map((leg, index) => (
                  <VirtualTicketBetRow
                    key={leg.legId}
                    editable={true}
                    mode={ticket_mode}
                    roundedTop={index !== 0}
                    leg={leg}
                    groups={groups}
                  />
                ))}
              </div>
            ))}
          </>
        )}
      </div>
      <TicketControls
        messages={messages}
        miza={miza}
        castig={castig}
        totalOdd={totalOdd}
        totalOddString={totalOddString}
        legal={legal}
        shortCode={shortCode}
        groups={groups}
        ticket_mode={ticket_mode}
        stake={stake}
      />
    </>
  );
};
const mapStateToPropsTicketEditor = (state: RootState) => {
  const { betslip } = state.virtualTicket;

  const miza = betslip?.toPay ?? 0;
  const totalOdd = betslip?.totalOdds ?? 0;
  const totalOddString = `${totalOdd?.toFixed(2)}`;
  const ticket_mode = betslip?.betslipType ?? 'NONE';
  const messages: TicketMessage[] = [];
  const castig =
    betslip?.potentialPayoutDetails?.winNet ?? betslip?.potentialWinning ?? 0;
  const ticket_types = betslip?.availableBetslipTypes;
  //FIXME: TicketModeInfo and number of events (legs) on ticket;
  const ticket_mode_info = 'TICKET_MODE_INFO';
  const events = betslip?.legs.length ?? 0;
  let legs = betslip?.legs ?? [];
  const bets: { [id: string]: Bet } = {};
  for (let leg of legs) {
    let oid = leg.selection.selection.selectionId;
    let bet = state.offer.bets[leg.selection.market.marketId];
    bets[oid] = bet;
  }

  const legal = '(legal info)';

  const shortCode = betslip?.shortcode;
  const stake = betslip?.stake;

  // MUST FIX THE STAKE BECAUSE FEG MADE A MISTAKE
  if (stake) {
    let fixed_stake: FEG_BetslipStake = { ...stake };
    if (shortCode) {
      if (!fixed_stake.combi && fixed_stake.combiStakeEntries !== undefined) {
        fixed_stake.combi = {
          totalStakeLocked: false,
          systems: fixed_stake.combiStakeEntries.map((c, i) => {
            return {
              index: i,
              enabled: true,
              activeCombinationsCount: c.numOfLines,
              systemStake: {
                locked: false,
                stake: c.stakePerLine,
                netStake: c.stakePerLine,
                loyaltyPointsStake: 0,
              },
              combinationStake: {
                locked: false,
                stake: c.stakePerLine,
                netStake: c.stakePerLine,
                loyaltyPointsStake: 0,
              },
            };
          }),
        };
      }
    }
  }

  return {
    legs,
    ticket_mode,
    messages,
    castig,
    miza,
    legal,
    totalOddString,
    totalOdd,
    groups: betslip?.groups ?? {},
    shortCode,
    stake,
  };
};

const mapDispatchActionsTicketEditor = (dispatch: Dispatch) => {
  return {
    dispatch,
  };
};
const ticketEditorConnector = connect(
  mapStateToPropsTicketEditor,
  mapDispatchActionsTicketEditor
);

type TicketEditorReduxProps = ConnectedProps<typeof ticketEditorConnector>;

const ConnectedTicketEditor = ticketEditorConnector(TicketEditor);
export default ConnectedTicketEditor;
