import React, {
  CSSProperties,
  MouseEventHandler,
  PropsWithChildren,
  SyntheticEvent,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as CloseIcon } from '../../img/close-56px.svg';
import { toggleCheckTicket } from '../../store/thunks';
import { ReactComponent as BackSpace } from '../../img/back-24px.svg';
import { ReactComponent as ShiftIcon } from '../../img/shift-24px.svg';
import TicketResults from './TicketResults';
import VirtualGamesTicketResults from './VirtualGamesTicketResults';
import { motion } from 'framer-motion';
import { RootState } from '../../store/index';

interface Props {
  isTouchScreen: boolean;
  receivedTicketId?: string;
  receivedProtectionCode?: string;
  receivedTicketIsVirtual?: boolean;
}
enum Step {
  TicketId,
  ProtectionCode,
}

interface TicketDetails {
  id: string;
}

enum TicketType {
  'SportsBettingLotto',
  'VirtualGames',
}

/**
 * long ticket for scroll
  const [ticketId, setTicketId] = useState<string>('622hnd');
  const [protectionCode, setProtectionCode] = useState<string>('1249'); */

const CheckTicket: React.FC<Props> = ({
  isTouchScreen,
  receivedTicketId,
  receivedProtectionCode,
  receivedTicketIsVirtual,
}) => {
  const [step, setStep] = useState<Step>(Step.TicketId);
  const [showTicket, setShowTicket] = useState<boolean>(
    (receivedTicketId?.length === 16 ||
      (receivedTicketId?.length === 9 && receivedTicketIsVirtual === true)) ??
      false
  );

  // TICKETS

  // LUCKY SIX
  // 0X06LFQ3O
  // 0X0WN3029 special
  // 0X1OAF1QN colors
  // 0X1DJTPJH Suma primelor nr (-122.5+) SUB
  // 0X2P4CN2J Suma primelor nr (-122.5+) PESTE

  // 0X0QAQMLI Mai multe nr par impar in primele 5
  // 0X23XELV3 Prima bila (-24.5+) SUB
  // 0X1L7WJ2H Culoare prima bila verde
  // 0X094ELE4 Culoare prima bila rosie
  // 0X0RUTC05 Culoare prima bila violet
  // 0X1CVWR1V Culoare prima bila albastra 8
  // 0X08PQ9UN Culoare prima bila neagra
  // 0X0ACNGEV Culoare prima bila portocalie 128
  // 0X1ECGQXL Culoare prima bila galbena 64
  // 0X0VX26UO Culoare prima bila maro 32
  // 0X011TBAS Toate Violet
  // 0X2P6IKWR Toate albastre
  // 0X1EDC9IR Toate verzi
  // 0X10C9R1I Toate rosii
  // 0X268HNCM Toate negre
  // 0X0DCDQ8O Toate portocalii
  // 0X2KN81KU Toate galbene
  // 0X20XDBJ0 Toate maro

  // DOGS
  // X01IIVDLR Castigator 5
  // X01JLWJ3V Primii 2 in orice ordine 2-4, 4-2
  // X007BG7K3 Primii 2 in orice ordine 1-3, 1-5, 3-1, 3-5, 5-1, 5-3
  // FTN: x027vcebd Primii 2 orice ordine
  // CP X007BG7K3 Ordine exacta
  // X02KJH3AG Castigatori
  // X10H7CBQD // LIVE

  const [ticketId, setTicketId] = useState<string>(receivedTicketId ?? '');
  const [protectionCode, setProtectionCode] = useState<string>(
    receivedProtectionCode ?? ''
  );
  const [ticketType, setTicketType] = useState<TicketType>(
    receivedTicketIsVirtual === true
      ? TicketType.VirtualGames
      : TicketType.SportsBettingLotto
  );

  const isEditing = !showTicket;
  const dispatch = useDispatch();
  const appId = useSelector((state: RootState) => state.system.uuid);

  const isValid = (): boolean => {
    switch (ticketType) {
      case TicketType.SportsBettingLotto: {
        return (
          protectionCode.length === 4 &&
          (ticketId.length === 6 || ticketId.length === 16)
        );
      }
      case TicketType.VirtualGames: {
        return ticketId.length === 9;
      }
    }
  };

  const handleCheckTicket = () => {
    if (isValid()) {
      setShowTicket(true);
    }
  };

  const handleBackSpace = () => {
    switch (step) {
      case Step.TicketId: {
        setTicketId(ticketId.slice(0, -1));
        break;
      }
      case Step.ProtectionCode: {
        setProtectionCode(protectionCode.slice(0, -1));
        break;
      }
    }
  };

  const handleKey = (key: string) => {
    switch (step) {
      case Step.TicketId: {
        setTicketId(`${ticketId}${key}`);
        break;
      }
      case Step.ProtectionCode: {
        setProtectionCode(`${protectionCode}${key}`);
        break;
      }
    }
  };
  const handleTicketNotFound = () => {
    setShowTicket(false);
  };

  return (
    <>
      <div
        style={{
          position: 'fixed',
          display: 'inline-block',
          left: 16,
          bottom: 16,
          color: '#707070',
          fontSize: 12,
          zIndex: 99999,
          fontFamily: 'monospace',
        }}
      >
        {appId}
      </div>
      <div className='fs-modal bg-darkest '>
        <CloseIcon
          className='closeButton z-40'
          onClick={() => {
            console.log('Why?');
            dispatch(toggleCheckTicket());
          }}
        />{' '}
        {showTicket === true &&
          ticketType === TicketType.SportsBettingLotto && (
            <TicketResults
              ticketId={ticketId}
              protectionCode={protectionCode}
              onError={handleTicketNotFound}
            />
          )}
        {showTicket === true && ticketType === TicketType.VirtualGames && (
          <VirtualGamesTicketResults
            ticketId={ticketId}
            onError={handleTicketNotFound}
          />
        )}
        {isEditing && (
          <div className='flex flex-col items-center content-center w-screen h-screen'>
            <h1 className='relative flex text-white items-center justify-center w-full box-border px-32 text-24 bold py-16 mt-32'>
              <span className='inline-block absolute left-[72px]'>
                Verificare bilet agenție
              </span>
              <div className='inline-flex items-center h-12 bg-back-tertiary rounded-full text-base bold '>
                <span
                  onClick={() => {
                    setTicketType(TicketType.SportsBettingLotto);
                    setStep(0);
                  }}
                  className={`inline-flex items-center px-24 h-12 flex-1 whitespace-nowrap ${
                    ticketType === TicketType.SportsBettingLotto
                      ? 'bg-feg-yellow  text-black rounded-full'
                      : ''
                  } `}
                >
                  Pariuri Sportive / Lotto
                </span>
                <span
                  onClick={() => {
                    setTicketType(TicketType.VirtualGames);
                    setStep(0);
                  }}
                  className={`inline-flex items-center px-24 h-12 flex-1 whitespace-nowrap ${
                    ticketType === TicketType.VirtualGames
                      ? 'bg-feg-yellow  text-black rounded-full'
                      : ''
                  } `}
                >
                  Jocuri Virtuale
                </span>
              </div>
            </h1>
            <div className='flex flex-col flex-grow items-center content-center'>
              <div className='flex items-center content-center h-56 mb-48'>
                <InputBox
                  value={ticketId}
                  placeholder={
                    ticketType === TicketType.SportsBettingLotto
                      ? 'Cod scurt / număr bilet'
                      : 'Cod bilet'
                  }
                  active={step === Step.TicketId}
                  style={{ width: '280px' }}
                  onClick={() => {
                    setStep(Step.TicketId);
                  }}
                />
                {ticketType === TicketType.SportsBettingLotto && (
                  <InputBox
                    value={protectionCode}
                    placeholder='Cod verificare'
                    active={step === Step.ProtectionCode}
                    style={{ width: '200px' }}
                    onClick={() => {
                      setStep(Step.ProtectionCode);
                    }}
                  />
                )}

                <motion.div
                  initial={{ backgroundColor: '#414141' }}
                  whileTap={{ backgroundColor: '#ffd639' }}
                  onClick={handleBackSpace}
                  className='ml-4 inline-flex items-center content-center h-56 w-56 bg-darker rounded-4'
                >
                  <BackSpace />
                </motion.div>
              </div>
              <div>
                {step === Step.TicketId && <FullKeyPad onChange={handleKey} />}
                {step === Step.ProtectionCode && (
                  <NumericKeyPad onChange={handleKey} />
                )}
              </div>
              <motion.div
                style={{ minWidth: 320 }}
                onClick={handleCheckTicket}
                whileTap={{
                  backgroundColor: isValid() ? '#F0BF00' : '#414141',
                }}
                className={`ml-4 inline-flex items-center content-center h-56 ${
                  isValid() ? 'background-yellow' : 'background-gray'
                } rounded-4 text-16 bold`}
              >
                Verifică
              </motion.div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default CheckTicket;

interface InputBoxProps {
  style?: CSSProperties;
  value?: string;
  active?: boolean;
  placeholder?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}
const InputBox: React.FC<InputBoxProps> = (props) => {
  const { style, value, active, placeholder, onClick } = props;
  const showPlaceholder =
    placeholder !== undefined && (value ?? '').length === 0;
  const showValue = (value ?? '').length > 0;
  return (
    <div
      style={style}
      onClick={onClick}
      className={`inline-block h-56  bg-white mr-2 px-24 box-border rounded-4 text-dark text-16 bold ${
        active === true ? 'border-2 border-yellow border-solid lh-52' : 'lh-56'
      }`}
    >
      {showPlaceholder && (
        <span className='text-gray text-16 bold'>{placeholder}</span>
      )}
      {showValue && <>{value}</>}
    </div>
  );
};

interface KeyboardProps {
  onChange: (key: string) => void;
}

const FullKeyPad: React.FC<KeyboardProps> = (props) => {
  const { onChange } = props;
  const [caps, setCaps] = useState(false);
  const handleKey = (e: SyntheticEvent<HTMLDivElement>) => {
    const key = e.currentTarget.dataset.value ?? '';
    onChange(caps ? key?.toUpperCase() : key);
  };
  return (
    <div className='flex flex-col items-center content-center py-24'>
      <div className='flex flex-row mb-24'>
        {['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].map(
          (key, index) => (
            <Key key={key} value={key} small onClick={handleKey}>
              {key}
            </Key>
          )
        )}
      </div>
      <div className='flex flex-row mb-18'>
        {['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'].map((key) => (
          <Key key={key} value={key} onClick={handleKey}>
            {caps ? key.toUpperCase() : key}
          </Key>
        ))}
      </div>
      <div className='flex flex-row mb-18'>
        {['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'].map((key) => (
          <Key key={key} value={key} onClick={handleKey}>
            {caps ? key.toUpperCase() : key}
          </Key>
        ))}
      </div>
      <div className='flex flex-row mb-18'>
        <Key
          key='lshift'
          value={'shift'}
          style={{ backgroundColor: '#414141', width: 104 }}
          onClick={() => {
            setCaps(!caps);
          }}
        >
          <ShiftIcon />
        </Key>
        {['z', 'x', 'c', 'v', 'b', 'n', 'm'].map((key) => (
          <Key key={key} value={key} onClick={handleKey}>
            {caps ? key.toUpperCase() : key}
          </Key>
        ))}
        <Key
          key='rshift'
          value={'shift'}
          style={{ backgroundColor: '#414141', width: 104 }}
          onClick={() => {
            setCaps(!caps);
          }}
        >
          <ShiftIcon />
        </Key>
      </div>
    </div>
  );
};

const NumericKeyPad: React.FC<KeyboardProps> = (props) => {
  const { onChange } = props;

  const handleKey = (e: SyntheticEvent<HTMLDivElement>) => {
    const key = e.currentTarget.dataset.value ?? '';
    onChange(key);
  };
  return (
    <div className='grid grid-rows-4 grid-cols-3 items-center content-center py-24 gap-18'>
      {['7', '8', '9', '4', '5', '6', '1', '2', '3'].map((key, index) => (
        <Key key={key} value={key} style={{ width: 104 }} onClick={handleKey}>
          {key}
        </Key>
      ))}
      <div></div>
      <Key value='0' style={{ width: 104 }} onClick={handleKey}>
        0
      </Key>
    </div>
  );
};

const Key: React.FC<
  PropsWithChildren & {
    value: string;
    small?: boolean;
    style?: CSSProperties;
    onClick?: MouseEventHandler | ((e: SyntheticEvent<HTMLDivElement>) => void);
  }
> = (props) => {
  const { value, small, children, style, onClick } = props;
  return (
    <motion.div
      style={style}
      data-value={value}
      onClick={onClick}
      whileTap={{ backgroundColor: '#F0BF00' }}
      className={`ml-4 mx-8 inline-flex items-center content-center background-white rounded-5 text-28 text-dark medium ${
        small === true ? 'h-48 w-64' : 'h-64 w-64'
      }`}
    >
      {children}
    </motion.div>
  );
};
