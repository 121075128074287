import React, { useState } from 'react';
import { RootState } from '../../store';
import { Dispatch } from 'redux';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
// import { ReactComponent as TicketIcon } from '../../img/vticket-24px.svg';
import { ReactComponent as ArrowUp } from '../../img/arrow-up-24px.svg';
import { ReactComponent as ArrowDown } from '../../img/arrow-down-24px.svg';
import { ReactComponent as ShowMoreIcon } from '../../img/arrow-more-24px.svg';
import { toggleTicketExpanded } from '../../store/ticket/ticket-actions';
import { deleteVirtualTicketMessage } from '../../store/ticket/ticket-actions';
// import Spinner from '../Spinner';
import {
  changeTicketType,
  deleteCurrentVirtualTicket,
} from '../../store/thunks';
import FullScreenMessageBox from '../FullScreenMessageBox';
import { BETSLIP_TYPE_LABELS, TicketMessage } from '../../store/ticket/ticket-types';

const VirtualTicketEditorHeader: React.FC<TicketHeaderReduxProps> = (props) => {
  const [showTicketTypes, setShowTicketTypes] = useState(false);
  const [
    showDeleteTicketConfirmation,
    setShowDeleteTicketConfirmation,
  ] = useState<boolean>(false);
  const {
    miza,
    totalOddString,
    ticket_mode,
    ticket_mode_info,
    messages,
    currentTicket,
    ticket_types,
    castig,
    toggleExpanded,
    isExpanded,
    isEmpty,
    events,
  } = props;

  const dispatchThunk = useDispatch();
  const dispatch = useDispatch();

  const handleExpand = () => {
    console.log('handleExpanded');
    toggleExpanded();
  };

  const showTicketTypeOptions = (e: React.SyntheticEvent<HTMLDivElement>) => {
    console.log('showTicketTypeOptions');
    e.stopPropagation();
    setShowTicketTypes(!showTicketTypes);
  };

  const handleChangeTicketType = (e: React.SyntheticEvent<HTMLDivElement>) => {
    console.log('handleChangeTickeType');
    e.stopPropagation();

    const type = e.currentTarget.dataset.type;
    if (!type) {
      console.error('type is undefined');
      return;
    }
    dispatchThunk(changeTicketType(type));
    setShowTicketTypes(false);
  };

  const handleDeleteMessage = (e: React.SyntheticEvent<HTMLSpanElement>) => {
    // const checked = !(e.currentTarget.dataset.checked === 'true');
    // const index = Number(e.currentTarget.dataset.index);
    const index = 0;
    dispatch(deleteVirtualTicketMessage(index));
    e.stopPropagation();
  };

  const showMessage = !isExpanded && messages.length > 0;

  return (
    <>
      {showDeleteTicketConfirmation && (
        <FullScreenMessageBox
          message={`Ești sigur că vrei să ștergi biletul?`}
          cancelLabel='Închide'
          continueLabel='Da'
          onCancel={() => setShowDeleteTicketConfirmation(false)}
          onContinue={() => {
            dispatchThunk(deleteCurrentVirtualTicket());
            setShowDeleteTicketConfirmation(false);
          }}
        />
      )}
      <div className='ticket-header' onClick={handleExpand}>
        <div
          style={{ display: 'inline-block', marginRight: 16, paddingTop: 4 }}
        >
          {isExpanded && <ArrowDown />}
          {!isExpanded && <ArrowUp />}
        </div>

        <div className='v-stack margin-r-36'>
          <span className='ticket-header-label'>Cota totala</span>
          <span className='ticket-header-value'>
            {isEmpty ? '-.--' : totalOddString}
          </span>
        </div>
        <div className='v-stack margin-r-36'>
          <span className='ticket-header-label'>Miza</span>
          <span className='ticket-header-value'>
            {isEmpty ? '-.--' : (miza ?? 0).toFixed(2)}
          </span>
        </div>
        <div className='v-stack margin-r-36'>
          <span className='ticket-header-label'>Castig posibil</span>
          <span className='ticket-header-value'>
            {isEmpty || castig === null || castig === 0
              ? '-.--'
              : (castig ?? 0).toFixed(2)}
          </span>
        </div>
        <div className='v-stack'>
          <span className='ticket-header-label'>Evenimente</span>
          <span
            className={`ticket-header-value ${isEmpty ? '' : 'text-center'}`}
          >
            {isEmpty ? '-.--' : events}
          </span>
        </div>
        <div className='flex-spacer' />
        {isEmpty && (
          <div className='inline-block text-13 semibold px-24'>
            Biletul este gol
          </div>
        )}
        {showMessage && (
          <div
            style={{
              maxWidth: 460,
              height: 44,
              borderRadius: 6,
              marginRight: -12,
            }}
            className={`inline-flex items-center content-center text-12 semibold px-8 py-4 ${messages[0].class}`}
            onClick={handleDeleteMessage}
          >
            {messages[0].text}
          </div>
        )}
        {!isEmpty && !showMessage && (
          <>
            {!isExpanded && (
              <div className='inline-flex items-center content-center rounded-4 text-13 semibold h-36 w-42 box-border mr-8 shadow bg-white'>
                {currentTicket}
              </div>
            )}
            {!showTicketTypes && (
              <div className='ticket-type' onClick={showTicketTypeOptions}>
                <span className='inline-block'>{ticket_mode_info}</span>
                <span className='inline-block'>
                  <ShowMoreIcon />
                </span>
              </div>
            )}

            {showTicketTypes && (
              <div className='inline-flex items-end rounded-4 text-13 semibold h-36 mr-8 shadow'>
                <div className=''>
                  {(ticket_types ?? []).map((type, index) => (
                    <div
                      key={type}
                      style={{ width: 140 }}
                      onClick={handleChangeTicketType}
                      data-type={type}
                      className={`bg-white h-36 lh-36 px-12 box-border ${index === 0 ? 'rounded-t' : ''
                        } ${index === ((ticket_types?.length ?? 0) - 1)}
                          ? 'rounded-b'
                          : 'border-solid border-b border-gray'
                        }`}
                    >
                      {BETSLIP_TYPE_LABELS[type] ?? "?"}
                    </div>
                  ))}
                </div>
              </div>
            )}
            {isExpanded && (
              <div
                className='ticket-button'
                onClick={(e) => {
                  e.stopPropagation();
                  setShowDeleteTicketConfirmation(true);
                }}
              >
                <span className='inline-block'>Sterge bilet</span>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

const mapStateToPropsTicketHeader = (state: RootState) => {
  const {
    currentTicket,
    isExpanded,
    betslip,
    isShowingPreparedTickets,
  } = state.virtualTicket;

  const miza = betslip?.toPay;
  const totalOdd = betslip?.totalOdds;
  const totalOddString = `${totalOdd?.toFixed((2))}`;
  const ticket_mode = betslip?.betslipType;
  const messages: TicketMessage[] = [];
  const castig = betslip?.potentialPayoutDetails?.winNet ?? betslip?.potentialWinning ?? 0;
  const ticket_types = betslip?.availableBetslipTypes;
  const events = betslip?.legs.length ?? 0;
  const ticket_mode_info = BETSLIP_TYPE_LABELS[betslip?.betslipType ?? "NONE"];
  
  return {
    miza,
    totalOdd,
    totalOddString,
    ticket_mode,
    ticket_mode_info,
    ticket_types,
    messages,
    currentTicket,
    castig,
    isShowingPreparedTickets,
    isExpanded,
    events,
    isEmpty: betslip === undefined,
  };
};

const mapDispatchActionsTicketHeader = (dispatch: Dispatch) => {
  return {
    dispatch,
    toggleExpanded: () => dispatch(toggleTicketExpanded()),
  };
};
const ticketHeaderConnector = connect(
  mapStateToPropsTicketHeader,
  mapDispatchActionsTicketHeader
);

type TicketHeaderReduxProps = ConnectedProps<typeof ticketHeaderConnector>;

const ConnectedVirtualTicketEditorHeader = ticketHeaderConnector(
  VirtualTicketEditorHeader
);
export default ConnectedVirtualTicketEditorHeader;
