import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ReactComponent as ArrowDown } from '../../img/step-down.svg';
import { ReactComponent as XIcon } from '../../img/x-12px.svg';
import {
  TicketMessage,
  FEG_BetslipGroup,
  FEG_BetslipStake,
  FEG_BetslipStakeCombiSystem,
} from '../../store/ticket/ticket-types';
import { deleteVirtualTicketMessage } from '../../store/ticket/ticket-actions';
import {
  changeCombiBetGroupValue,
  changeSystemBetGroupChecked,
  changeSystemBetGroupValue,
  virtualTicketChangeMiza,
  virtualTicketPrepare,
  virtualTicketResetMiza,
} from '../../store/thunks';

import NumericKeyboard, { NumericKeyboardProps } from '../NumericKeyboard';
import { ReactComponent as CheckMark } from '../../img/checkmark-11px.svg';

interface TicketControlsProps {
  messages: TicketMessage[];
  miza: number;
  totalOdd: number;
  totalOddString: string;
  legal: string;
  castig: number;
  shortCode?: string;
  groups: { [id: string]: FEG_BetslipGroup };
  ticket_mode: string;
  stake?: FEG_BetslipStake;
}

function getCombiSystemInfo(
  combi_system: FEG_BetslipStakeCombiSystem | undefined,
  stake: FEG_BetslipStake | undefined
): String {
  if (!stake || !stake.combi || !combi_system) {
    return '-/- (-) fiecare';
  }
  const info = `${combi_system?.index ?? '-'}/${
    stake?.combi?.systems.length
  } (${combi_system?.activeCombinationsCount}) fiecare`;
  return info;
}

function getCombiSystemValue(
  combi_system: FEG_BetslipStakeCombiSystem | undefined
): number {
  return combi_system?.enabled
    ? combi_system.combinationStake.stake
    : combi_system?.systemStake.stake ?? 0;
}

const TicketControls: React.FC<TicketControlsProps> = (props) => {
  const {
    messages,
    miza,
    castig,
    totalOdd,
    totalOddString,
    legal,
    shortCode,
    ticket_mode,
    stake,
  } = props;
  const [isNumKBVisible, setIsNumKBVisible] = useState(false);
  const [kbOptions, setKbOptions] = useState<NumericKeyboardProps>({
    initialValue: 1,
    title: '',
  });
  const dispatch = useDispatch();

  const handleDeleteMessage = (e: React.SyntheticEvent<HTMLSpanElement>) => {
    // const checked = !(e.currentTarget.dataset.checked === 'true');
    const index = Number(e.currentTarget.dataset.index);
    dispatch(deleteVirtualTicketMessage(index));
  };

  const showKeyboardForMiza = () => {
    setKbOptions({
      initialValue: miza,
      title: 'Modifică miza: ',
      onCancel: () => {
        setIsNumKBVisible(false);
      },
      onComplete: (newMiza) => {
        console.log('got new Miza ', newMiza);
        setIsNumKBVisible(false);
        dispatch(virtualTicketChangeMiza(newMiza));
      },
    });
    setIsNumKBVisible(true);
  };
  const showKeyboardForCombiBetGroup = (
    e: React.SyntheticEvent<HTMLSpanElement>
  ) => {
    // const checked = !(e.currentTarget.dataset.checked === 'true');
    const index = Number(e.currentTarget.dataset.index);
    const value = e.currentTarget.dataset.value;

    if (isNaN(index)) {
      return;
    }
    if (!stake) {
      return;
    }
    const combi_system = stake?.combi?.systems.find(
      (group) => group.index === index
    );
    const info = getCombiSystemInfo(combi_system, stake);
    setKbOptions({
      initialValue: Number(value),
      title: `Modifică Miza la Grup: ${info}`,
      onCancel: () => {
        setIsNumKBVisible(false);
      },
      onComplete: (newMiza) => {
        console.log('got new Miza ', newMiza);
        setIsNumKBVisible(false);
        dispatch(changeCombiBetGroupValue(true, `${index}`, newMiza));
      },
    });
    setIsNumKBVisible(true);
  };
  const showKeyboardForCombiBetCombination = (
    e: React.SyntheticEvent<HTMLSpanElement>
  ) => {
    // const checked = !(e.currentTarget.dataset.checked === 'true');
    const index = Number(e.currentTarget.dataset.index);
    const value = e.currentTarget.dataset.value;

    if (isNaN(index)) {
      return;
    }
    const combi_system = stake?.combi?.systems.find(
      (group) => group.index === index
    );
    let info = getCombiSystemInfo(combi_system, stake);

    setKbOptions({
      initialValue: Number(value),
      title: `Modifică Miza la Combinație: ${info}`,
      onCancel: () => {
        setIsNumKBVisible(false);
      },
      onComplete: (newMiza) => {
        console.log('got new Miza ', newMiza);
        setIsNumKBVisible(false);
        dispatch(changeCombiBetGroupValue(false, `${index}`, newMiza));
      },
    });
    setIsNumKBVisible(true);
  };

  const showKeyboardForSystemBetGroup = (
    e: React.SyntheticEvent<HTMLSpanElement>
  ) => {
    // const checked = !(e.currentTarget.dataset.checked === 'true');
    const index = Number(e.currentTarget.dataset.index);
    const value = e.currentTarget.dataset.value;

    if (isNaN(index)) {
      return;
    }
    if (!stake) {
      return;
    }

    // SYSTEM COMBINATION INFO
    const combi_system = stake.combi?.systems.find(
      (group) => group.index === index
    );

    let info = getCombiSystemInfo(combi_system, stake);
    setKbOptions({
      initialValue: Number(value),
      title: `Modifică Miza la Combinație: ${info}`,
      onCancel: () => {
        setIsNumKBVisible(false);
      },
      onComplete: (newMiza) => {
        console.log('got new Miza ', newMiza);
        setIsNumKBVisible(false);
        dispatch(changeSystemBetGroupValue(`${index}`, newMiza));
      },
    });
    setIsNumKBVisible(true);
  };

  const handleChangeMizaNextStep = (up: boolean) => {
    const steps = [
      1.0, 3.0, 5.0, 10.0, 20.0, 30.0, 50.0, 100.0, 200.0, 300.0, 500.0,
    ];
    let nextValue: number | undefined = undefined;
    if (up) {
      nextValue = steps.find((value) => value > miza);
    } else {
      for (let i = steps.length - 1; i >= 0; i--) {
        if (steps[i] < miza) {
          nextValue = steps[i];
          break;
        }
      }
    }
    console.log(nextValue);
    if (nextValue) {
      dispatch(virtualTicketChangeMiza(nextValue));
    }
  };

  const handleResetMiza = () => {
    dispatch(virtualTicketResetMiza());
  };

  const prepareTicket = () => {
    if (shortCode) {
      return;
    }
    dispatch(virtualTicketPrepare());
  };

  useEffect(() => {
    console.log(`Controls, ticket mode: ${ticket_mode}`);
  }, [ticket_mode]);

  const handleSystemBetGroupCheckbox = (
    e: React.SyntheticEvent<HTMLSpanElement>
  ) => {
    const checked = !(e.currentTarget.dataset.checked === 'true');
    const index = e.currentTarget.dataset.index;
    if (!index) {
      return;
    }
    dispatch(changeSystemBetGroupChecked(index, checked));
  };

  return (
    <>
      {isNumKBVisible && (
        <div className='fs-modal flex items-center justify-center '>
          <NumericKeyboard
            title={kbOptions.title}
            initialValue={kbOptions.initialValue}
            onCancel={kbOptions.onCancel}
            onComplete={kbOptions.onComplete}
          />
        </div>
      )}
      {!isNumKBVisible && (
        <div className='ticket-controls'>
          <div className='text-white overflow-y-scroll'>
            {messages.length > 0 && (
              <div className='ticket-messages'>
                {messages.map((message, index) => (
                  <div
                    key={`${index}-${message.text}`}
                    data-index={index}
                    className={`ticket-message ${message.class}`}
                    onClick={handleDeleteMessage}
                  >
                    {message.text}
                  </div>
                ))}
              </div>
            )}
            {ticket_mode === 'LEG_COMBI' && (
              <>
                <div className='flex space-between h32 lh-32 mb-2'>
                  <span className='bg-gray-gradient flex-grow rounded-t-4 px-8 text-center text-11 text-yellow semibold mr-1 whitespace-nowrap'>
                    Miză la Combinație
                  </span>
                  <span
                    className='inline-flex items-center justify-center  bg-gray-gradient flex-grow-0 rounded-t-4 px-8 text-11 text-center text-white semibold w-52 box-border whitespace-nowrap'
                    onClick={handleResetMiza}
                  >
                    <XIcon />
                  </span>
                </div>
                {stake?.combi?.systems.map((combi_system) => (
                  <div
                    key={`${getCombiSystemInfo(combi_system, stake)}`}
                    className='flex space-between h32 lh-32 mb-2'
                  >
                    <span
                      data-index={combi_system.index}
                      data-checked={combi_system.enabled}
                      className='inline-flex items-center justify-center  bg-gray-gradient px-4 py-4 rounded-4 mr-1'
                      onClick={handleSystemBetGroupCheckbox}
                    >
                      <CheckBox checked={combi_system.enabled} />
                    </span>
                    <span className='bg-gray-gradient flex-grow rounded-4 px-8 text-11 semibold mr-1 whitespace-nowrap'>
                      {`${getCombiSystemInfo(combi_system, stake)}`}
                    </span>
                    <span
                      data-index={combi_system.index}
                      data-value={getCombiSystemValue(combi_system)}
                      className='bg-white w-84 flex-shrink-0 rounded-4 text-13 text-dark text-right px-12 semibold box-border'
                      onClick={showKeyboardForSystemBetGroup}
                    >
                      {getCombiSystemValue(combi_system)}
                    </span>
                  </div>
                ))}
              </>
            )}

            {ticket_mode === 'GROUP_COMBI' && (
              <>
                <div className='flex space-between h32 lh-32 mb-2'>
                  <span className='bg-gray-gradient flex-grow rounded-t-4 px-8 text-center text-11 text-yellow semibold mr-1 whitespace-nowrap'>
                    Miză la
                  </span>
                  <span
                    className='inline-flex items-center justify-center bg-gray-gradient flex-grow-0 rounded-t-4 px-8 text-11 text-center text-white semibold w-52 box-border whitespace-nowrap'
                    onClick={handleResetMiza}
                  >
                    <XIcon />
                  </span>
                </div>
                <div className='flex space-between h32 lh-32 mb-2'>
                  <span className='bg-gray-gradient w-84 flex-shrink-0 rounded-t-4 px-8 text-center text-11 text-white semibold mr-1 whitespace-nowrap box-border'>
                    GRUP
                  </span>
                  <span className='bg-gray-gradient flex-grow rounded-t-4 px-8 text-center text-11 text-yellow semibold mr-1 whitespace-nowrap'></span>
                  <span className='bg-gray-gradient w-84 flex-shrink-0 rounded-t-4 px-8 text-center text-11 text-white semibold mr-1 whitespace-nowrap box-border'>
                    COMB.
                  </span>
                </div>
                {stake?.combi?.systems.map((combi_system) => (
                  <div
                    key={`${getCombiSystemInfo(combi_system, stake)}`}
                    className='flex space-between h32 lh-32 mb-2'
                  >
                    <span
                      data-index={combi_system.index}
                      data-value={getCombiSystemValue(combi_system)}
                      className='bg-white w-84 flex-shrink-0 rounded-4 text-13 text-dark text-center px-12 semibold box-border mr-1'
                      onClick={showKeyboardForCombiBetGroup}
                    >
                      {`${combi_system.index}/${stake.combi?.systems.length}`}
                    </span>
                    <span className='bg-gray-gradient flex-grow rounded-4 px-8 text-11 semibold mr-1 whitespace-nowrap'>
                      {getCombiSystemInfo(combi_system, stake)}
                    </span>
                    <span
                      data-index={combi_system.index}
                      data-value={getCombiSystemValue(combi_system)}
                      className='bg-white w-84 flex-shrink-0 rounded-4 text-13 text-dark text-center px-12 semibold box-border'
                      onClick={showKeyboardForCombiBetCombination}
                    >
                      {getCombiSystemValue(combi_system)}
                    </span>
                  </div>
                ))}
              </>
            )}
          </div>
          <div className='flex-spacer'></div>
          <div className='flex space-between h40 lh40'>
            <span className='ticket-label inline-block'>Cota totală</span>
            <b className='ticket-label text-yellow inline-block'>
              {totalOddString}
            </b>
          </div>
          <div className='flex space-between'>
            <span className='ticket-label inline-block h40 lh40 mr12'>
              Achitat:
            </span>
            <div
              className='vinput text-right flex-grow h40 lh40'
              onClick={showKeyboardForMiza}
            >
              {miza}
            </div>
            <StepperButton
              up={true}
              down={false}
              onClick={() => {
                handleChangeMizaNextStep(true);
              }}
            />
            <StepperButton
              down={true}
              up={false}
              onClick={() => {
                handleChangeMizaNextStep(false);
              }}
            />
          </div>
          <div className='flex space-between h40 lh40'>
            <span className='ticket-label inline-block'>Plată max.</span>
            <b className='ticket-label text-yellow inline-block'>{castig}</b>
          </div>

          <div className='flex h-56'>
            <div
              className={`text-13 vactive-input flex-grow h-44 justify-center px-24 ${
                shortCode ? '' : 'bg-yellow'
              } text-bold`}
              onClick={prepareTicket}
            >
              {shortCode ? `${shortCode}` : 'Pregătiți un bilet'}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const StepperButton: React.FC<{
  up?: boolean;
  down?: boolean;
  onClick?: () => void;
}> = (props) => {
  const { up, down, onClick } = props;
  return (
    <b
      className='inline-flex items-center justify-center bg-gray-gradient radius4 h40 lh40 w50 ml1 text-center'
      onClick={onClick}
    >
      {up && <ArrowDown style={{ transform: 'rotate(180deg)' }} />}
      {down && <ArrowDown />}
    </b>
  );
};

const CheckBox: React.FC<{ checked: boolean }> = (props) => {
  const { checked } = props;

  return (
    <>
      {!checked && (
        <span className='inline-flex items-center justify-center w-24 h-24 border-2 border-solid  rounded-8 box-border bg-white border-gray'></span>
      )}
      {checked && (
        <span className='inline-flex items-center justify-center w-24 h-24 text-center border-2 border-solid  rounded-8 box-border bg-yellow border-yellow'>
          <CheckMark />
        </span>
      )}
    </>
  );
};

export default TicketControls;
