/** @format */
import { StoreTypes } from '..';
import {
  initApp,
  receiveInitialData,
  updateIsCheckingTicket,
  wsConnect,
  startSession,
  updatePixel,
  terminateSession,
} from '../system/actions';
import {
  changeSelectedDate,
  updateToday,
  resetOffer,
  updateSelectedOdds,
  selectPendingOdd,
} from '../offer/actions';
import {
  sendTicketsToAppError,
  sendTicketsToAppComplete,
  sendTicketsToAppLoading,
  virtualTicketStartSession,
  virtualTicketUpdate,
  resetAllTickets,
  showTicket,
} from '../ticket/ticket-actions';
import { OfferStore } from '../types';
import {
  getAppCredentials,
  updateAppCredentials,
  Credentials,
  AppConfiguration,
  updateAppConfig,
  getBetslipsAPI,
} from '../../hardware';
import { FEG_Betslip, VirtualTicketState } from '../ticket/ticket-types';
import { wsMessage } from '../system/actions';

const API_SYSTEM = process.env.REACT_APP_API;
const API_CC = process.env.REACT_APP_API_CC;

export const thunkInitApp =
  (isTouchscreen: boolean): StoreTypes.AppThunk =>
  async (dispatch, getState) => {
    // const appId = getAppCredentials()?.id;

    // const restrictedIDS: string[] = [
    //   '74f5f886-90a6-41f4-94f5-8805917fa75e',
    //   '2df70eab-461c-4497-be89-d661283e7594',
    // ];

    // if (appId && restrictedIDS.indexOf(appId) >= 0) {
    //   console.warn(`found restricted id ${appId}.. reloading`);
    //   resetAppCredentials();
    //   reloadApp();
    // }

    const authResult = await appAuth();

    updateAppCredentials(authResult.credentials);
    updateAppConfig(authResult.config);

    dispatch(wsConnect(authResult.config.wssAPI));
    if (authResult.credentials.id === undefined) {
      console.error('Invalid credentials');
      return;
    }

    if (authResult.config.hasPixel) {
      helloPixel(authResult.credentials.id ?? 'XXXZZZ').then(({ _id }) => {
        dispatch(updatePixel(_id));
        dispatch(serverPairPixel());
      });
    }

    dispatch(
      initApp(authResult.credentials.id, isTouchscreen, authResult.config)
    );

    // dispatch(virtualTicketSessionStart());
    const retry = () => {
      return fetchInitialData().then(
        (data) => {
          dispatch(resetOffer(data));
          dispatch(receiveInitialData('01:23'));
          dispatch(updateToday());
          dispatch(setInitialDate());
          dispatch(startSession());
        },
        (error) => {
          console.error('error fetching data ', error);
          setTimeout(() => dispatch(retry), 2000);
        }
      );
    };
    retry();
  };

async function appAuth(): Promise<{
  credentials: Credentials;
  config: AppConfiguration;
}> {
  const credentials = getAppCredentials();
  let newCredentials: Credentials;
  let newConfig: AppConfiguration;

  return new Promise((resolve, reject) => {
    const retryFetch = async () => {
      try {
        const result = await fetch(`${API_CC}/auth`, {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(credentials),
        }).then((response) => response.json());
        newCredentials = result.credentials as Credentials;
        newConfig = result.config as AppConfiguration;
        resolve({ credentials: newCredentials, config: newConfig });
      } catch (err) {
        setTimeout(retryFetch, 2000);
      }
    };
    retryFetch();
  });
}

async function helloPixel(code: string): Promise<{ _id: string }> {
  return new Promise((resolve, reject) => {
    const retryFetch = async (interval: number) => {
      try {
        const result = await fetch(`http://localhost:8080/auth/identity`, {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ code }),
        }).then((response) => response.json());
        const { _id } = result;

        if (!_id) {
          throw Error("pixel doesn't have an _id yet");
        }
        resolve({ _id: _id });
      } catch (err) {
        console.error('Hello pixel error', err);
        const delay = Math.min(2 * interval, 5 * 60 * 1000);
        setTimeout(() => retryFetch(delay), delay);
      }
    };
    retryFetch(1000);
  });
}

export const serverPairPixel =
  (): StoreTypes.AppThunk => (dispatch, getState) => {
    const state = getState();
    const { isPixelPairedOnServer, pixelId } = state.system;
    if (isPixelPairedOnServer) {
      return;
    }
    dispatch(
      wsMessage({ type: 'pixelUpdate', id: pixelId, random: Date.now() })
    );
    setTimeout(() => {
      dispatch(serverPairPixel());
    }, 2000);
  };

// Standard variation
function fetchJSON<T>(url: string): Promise<T> {
  return fetch(url).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json() as Promise<T>;
  });
}

function fetchInitialData() {
  return fetchJSON<OfferStore.OfferData>(`${API_SYSTEM}offer.json`);
}

export const changeDate =
  (date: string): StoreTypes.AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    let category = `${state.offer.days[date].categories[0]}`;
    if (
      state.offer.days[date].categories.findIndex((c, i) => `${c}` === '3') >= 0
    ) {
      category = '3';
    }
    dispatch(changeSelectedDate(date, category));
  };

export const setInitialDate =
  (): StoreTypes.AppThunk => (dispatch, getState) => {
    const state = getState();
    const date = state.offer.daysIds[0];
    let category = state.offer.days[date].categories[0];
    if (
      state.offer.days[date].categories.findIndex((c) => `${c}` === '3') >= 0
    ) {
      category = '3';
    }
    dispatch(changeSelectedDate(date, category));
  };

export const sendTicketToApp =
  (): StoreTypes.AppThunk => (dispatch, getState) => {
    const state = getState();
    const { token, id } = getAppCredentials();
    const { retailAppAPI } = state.system.config;

    // const ticket_date = (time) => {

    // }
    // FIXME:: send prepared tickets;
    const tickets: VirtualTicketState[] = [];
    // const tickets = state.virtualTicket.preparedTickets.map((ticket) => ({
    //   code: ticket.shortCode,
    //   barcode: ticket.ticketId,
    //   miza: ticket.miza,
    //   castig: ticket.castig,
    //   bets: ticket.codes.length,
    //   type: ticket.ticket_mode_info,
    //   odds: ticket.totalOddString,
    //   created: ticket.date_created,
    // }));
    //
    dispatch(sendTicketsToAppLoading());
    const params = { id, token, tickets };
    console.log(params);
    fetch(`${retailAppAPI}/client/send_tickets_to_app`, {
      method: 'post',
      headers: [['Content-Type', 'application/json']],
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((result) => {
        const { imageURL, imageDescription } = result;
        if (imageURL && imageDescription) {
          dispatch(sendTicketsToAppComplete(imageURL, imageDescription));
        }
      })
      .catch((err) => {
        dispatch(sendTicketsToAppError(`${err}`));
      });
  };

export const toggleCheckTicket =
  (): StoreTypes.AppThunk => (dispatch, getState) => {
    const state = getState();
    const { isCheckingTicket } = state.system;
    dispatch(updateIsCheckingTicket(!isCheckingTicket));
  };

export const virtualTicketAddBet =
  (
    code: string,
    oid: string,
    oddv: string,
    b_id: string
  ): StoreTypes.AppThunk =>
  (dispatch, getState) => {
    const {
      system: { sessionId },
      virtualTicket: { currentTicket },
    } = getState();
    let state = getState();
    /*     let ticket_number = state.virtualTicket.currentTicket; */

    if (state.virtualTicket.betslip == undefined) {
      // ticket is empty
      console.debug('virtualTicket add bet, ticket is empty.');
      fetch(`${getBetslipsAPI()}/new_ticket_with_bet/${oid}/${oddv}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => res.json())
        .then((json) => {
          console.log('virtualTicket new_ticket_with_bet json: ', json);
          dispatch(virtualTicketUpdate(json, currentTicket));
          dispatch(refreshSelectedOdds(json));
        });
    } else {
      console.debug('virtualTicket add bet, ticket not empty.');
      //ticket is not empty, check if bet is already selected.

      if (
        (getState().virtualTicket.betslip?.legs?.findIndex(
          (l) => l.selection.selection.selectionId === oid
        ) ?? -1) >= 0
      ) {
        dispatch(virtualTicketRemoveBet(code, oid, b_id));
        return;
      } else {
        document.dispatchEvent(new Event('click'));
        dispatch(selectPendingOdd(b_id, code, oid, true));

        fetch(
          `${getBetslipsAPI()}/add_bet/${
            state.virtualTicket.betslip.betslipId
          }/${oid}/${oddv}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
          .then((res) => res.json())
          .then((json) => {
            console.log('virtual ticket add bet, json: ', json);
            dispatch(selectPendingOdd(b_id, code, oid, false));
            const data = json as FEG_Betslip;

            dispatch(refreshSelectedOdds(json));
            dispatch(virtualTicketUpdate(json, currentTicket));
          })
          .catch((error) => {
            console.error('virtual ticket, error adding bet: ', error);
            dispatch(selectPendingOdd(b_id, code, oid, false));
          });
      }
    }
  };

export const virtualTicketRemoveBet =
  (code: string, oid: string, b_id: string | undefined): StoreTypes.AppThunk =>
  (dispatch, getState) => {
    const {
      system: { sessionId },
      virtualTicket: { currentTicket, betslip },
    } = getState();
    const OP = `virtualTicketRemoveBet code: [${code}] oid: [${oid}] `;
    document.dispatchEvent(new Event('click'));

    if (betslip === undefined) {
      console.warn('missing betslip');
      return;
    }

    let leg = betslip.legs.find(
      (l) => l.selection.selection.selectionId == oid
    );
    if (!leg) {
      console.warn('Missing leg for oid: ', oid);
      return;
    }
    dispatch(selectPendingOdd(b_id, code, oid, true));
    fetch(`${getBetslipsAPI()}/delete_bet/${betslip?.betslipId}/${leg.legId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((json) => {
        console.debug(OP, 'got json: ', json);
        const data = json as FEG_Betslip;
        dispatch(virtualTicketUpdate(json, currentTicket));
        dispatch(selectPendingOdd(b_id, code, oid, false));
        dispatch(refreshSelectedOdds(json));
      })
      .catch((error) => {
        console.error(OP, error);
        if (undefined !== b_id) {
          dispatch(selectPendingOdd(b_id, code, oid, false));
        }
      });
  };

export const virtualTicketChangeMiza =
  (miza: number): StoreTypes.AppThunk =>
  (dispatch, getState) => {
    const {
      system: { sessionId },
      virtualTicket: { currentTicket, betslip },
    } = getState();

    const OP = `virtualTicketChangeMixa [${miza}] `;
    document.dispatchEvent(new Event('click'));
    let currentBetslipId = betslip?.betslipId;
    if (!currentBetslipId) {
      console.warn(
        `Can't change miza for ticket ${currentTicket}. Missing currentBetslipId`
      );
      return;
    }

    fetch(`${getBetslipsAPI()}/set-stake/${currentBetslipId}/${miza}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((json) => {
        console.debug(OP, ' got json: ', json);
        dispatch(virtualTicketUpdate(json, currentTicket));
        const data = json as FEG_Betslip;
        dispatch(refreshSelectedOdds(json));
      })
      .catch((error) => {
        console.error(OP, error);
      });
  };

export const virtualTicketPrepare =
  (): StoreTypes.AppThunk => (dispatch, getState) => {
    const {
      system: { sessionId, uuid, isTouchscreen },
      virtualTicket: { betslip, currentTicket },
    } = getState();

    const OP = `virtualTicketPrepare`;
    document.dispatchEvent(new Event('click'));

    let currentBetslipId = betslip?.betslipId;
    if (!currentBetslipId) {
      console.warn(
        `Can't prepare ticket ${currentTicket}. Missing currentBetslipId`
      );
      return;
    }

    fetch(`${getBetslipsAPI()}/store/${currentBetslipId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((json) => {
        console.debug(OP, 'got json: ', json);
        dispatch(virtualTicketUpdate(json, currentTicket, true));
        const data = json as FEG_Betslip;
        dispatch(refreshSelectedOdds(json));

        dispatch(
          wsMessage({
            appId: uuid,
            sessionId,
            isTouchscreen,
            rx_action: {
              type: 'VT_PREPARED',
              code: data.shortcode,
              ticketId: data.betslipId,
              currentTicket,
              betslip,
              // ticketMode: data.ticket_mode,
              // ticketMiza: data.miza,
              // ticketBets: oids.length,
              // ticketCastig: data.castig,
              // ticketActiveId: data.activeId,
            },
            dt: 0,
          })
        );
      })
      .catch((error) => {
        console.error(OP, error);
      });
  };

export const changeCurrentTicket =
  (ticket: number): StoreTypes.AppThunk =>
  (dispatch, getState) => {
    const {
      virtualTicket: { currentTicket, betslips },
    } = getState();
    const OP = `changeCurrentTicket [${ticket}]`;
    document.dispatchEvent(new Event('click'));
    let betslip = betslips[ticket];
    dispatch(showTicket(ticket));
    if (betslip) {
      let betslipId = betslip.betslipId;
      fetch(`${getBetslipsAPI()}/ticket/${betslipId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => res.json())
        .then((json) => {
          console.log(OP, ' json: ', json);
          dispatch(virtualTicketUpdate(json, ticket));
          const data = json as FEG_Betslip;
          dispatch(refreshSelectedOdds(json));
        })
        .catch((error) => {
          console.error(OP, error);
        });
    } else {
      dispatch(updateSelectedOdds([], []));
    }
  };

export const changeTicketType =
  (type: string): StoreTypes.AppThunk =>
  (dispatch, getState) => {
    const {
      system: { sessionId },
      virtualTicket: { betslip, currentTicket },
    } = getState();
    const OP = `changeTicketType [${type}]`;
    document.dispatchEvent(new Event('click'));
    if (!betslip) {
      console.warn('ChangeTicketType, no betslip');
      return;
    }

    fetch(`${getBetslipsAPI()}/set-betslip-type/${betslip.betslipId}/${type}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(OP, ' json: ', json);
        const data = json as FEG_Betslip;
        if (!data) {
          console.error('did not receive ticket data');
          return;
        }
        dispatch(virtualTicketUpdate(json, currentTicket));
        dispatch(refreshSelectedOdds(json));
      })
      .catch((error) => {
        console.error(OP, error);
      });
  };

export const changeBetGroup =
  (code: string, oid: string, group: string): StoreTypes.AppThunk =>
  (dispatch, getState) => {
    const {
      virtualTicket: { currentTicket, betslip },
    } = getState();
    const OP = `changeBetGroup code: [${code}] oid: [${oid}] group: [${group}]`;
    document.dispatchEvent(new Event('click'));

    if (!betslip) {
      console.warn('changeFixedBet No ticket found!');
      return;
    }
    let leg = betslip.legs.find(
      (leg) => leg.selection.selection.selectionId == oid
    );
    if (!leg) {
      console.warn('changeFixedBet No leg found!');
      return;
    }

    fetch(
      `${getBetslipsAPI()}/leg-change-group/${betslip.betslipId}/${
        leg.legId
      }/${group}`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        console.log(OP, ' json: ', json);
        const data = json as FEG_Betslip;
        if (!data) {
          console.error('did not receive ticket data');
          return;
        }
        dispatch(virtualTicketUpdate(json, currentTicket));
        dispatch(refreshSelectedOdds(json));
      })
      .catch((error) => {
        console.error(OP, error);
      });
  };

export const changeFixedBet =
  (code: string, oid: string, fixed: boolean): StoreTypes.AppThunk =>
  (dispatch, getState) => {
    const {
      virtualTicket: { currentTicket, betslip },
    } = getState();
    const OP = `changeFixedBet code: [${code}] oid: [${oid}] group: [${fixed}]`;
    document.dispatchEvent(new Event('click'));
    if (!betslip) {
      console.warn('changeFixedBet No ticket found!');
      return;
    }
    let leg = betslip.legs.find(
      (leg) => leg.selection.selection.selectionId == oid
    );
    if (!leg) {
      console.warn('changeFixedBet No leg found!');
      return;
    }

    fetch(
      `${getBetslipsAPI()}/fix-sistem-leg/${betslip.betslipId}/${
        leg.legId
      }/${fixed}`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        console.log(OP, ' json: ', json);
        const data = json as FEG_Betslip;
        if (!data) {
          console.error('did not receive ticket data');
          return;
        }
        dispatch(virtualTicketUpdate(json, currentTicket));
        dispatch(refreshSelectedOdds(json));
      })
      .catch((error) => {
        console.error(OP, error);
      });
  };

export const changeSystemBetGroupChecked =
  (index: string, checked: boolean): StoreTypes.AppThunk =>
  (dispatch, getState) => {
    const {
      virtualTicket: { currentTicket, betslip },
    } = getState();
    const OP = `changeFixedBet index: [${index}] checked: [${checked}]`;
    document.dispatchEvent(new Event('click'));

    fetch(
      `${getBetslipsAPI()}/combi-set-state/${betslip?.betslipId}/${Number(
        index
      )}/${checked}`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        console.log(OP, ' json: ', json);
        const data = json as FEG_Betslip;
        if (!data) {
          console.error('did not receive ticket data');
          return;
        }
        dispatch(virtualTicketUpdate(json, currentTicket));
        dispatch(refreshSelectedOdds(json));
      })
      .catch((error) => {
        console.error(OP, error);
      });
  };

export const changeSystemBetGroupValue =
  (index: string, value: number): StoreTypes.AppThunk =>
  (dispatch, getState) => {
    const {
      virtualTicket: { currentTicket, betslip },
    } = getState();
    const OP = `changeSystemBetGroupMiza index: [${index}] value: [${value}]`;
    document.dispatchEvent(new Event('click'));

    fetch(
      `${getBetslipsAPI()}/combi-set-stake/${betslip?.betslipId}/${Number(
        index
      )}/${value}`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        console.log(OP, ' json: ', json);
        const data = json as FEG_Betslip;
        if (!data) {
          console.error('did not receive ticket data');
          return;
        }
        dispatch(virtualTicketUpdate(json, currentTicket));
        dispatch(refreshSelectedOdds(json));
      })
      .catch((error) => {
        console.error(OP, error);
      });
  };

export const changeCombiBetGroupValue =
  (group: boolean, index: string, value: number): StoreTypes.AppThunk =>
  (dispatch, getState) => {
    const {
      virtualTicket: { currentTicket, betslip },
    } = getState();
    const OP = `changeCombiBetGroupValue index: [${index}] value: [${value}]`;
    document.dispatchEvent(new Event('click'));

    fetch(
      `${getBetslipsAPI()}/combi-set-stake/${betslip?.betslipId}/${Number(
        index
      )}/${value}`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        console.log(OP, ' json: ', json);
        const data = json as FEG_Betslip;
        if (!data) {
          console.error('did not receive ticket data');
          return;
        }
        dispatch(virtualTicketUpdate(json, currentTicket));
        dispatch(refreshSelectedOdds(json));
      })
      .catch((error) => {
        console.error(OP, error);
      });
  };

// FIXME: virtualTicketResetMiza don't know what to do with it.
export const virtualTicketResetMiza =
  (): StoreTypes.AppThunk => (dispatch, getState) => {
    const {
      system: { sessionId },
      virtualTicket: { currentTicket },
    } = getState();
    const OP = 'virtualTicketResetMiza';
    document.dispatchEvent(new Event('click'));
    return;

    // fetch(`${getTicketsAPI()}/ticket/reset-miza`, {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   // body: JSON.stringify({
    //   //   sessionId,
    //   //   ticket: currentTicket,
    //   //   shopReady: shopReady[currentTicket - 1],
    //   // }),
    // })
    //   .then((res) => res.json())
    //   .then((json) => {
    //     console.log(OP, ' json: ', json);
    //     const data = json as FEG_Betslip;
    //     if (!data) {
    //       console.error('did not receive ticket data');
    //       return;
    //     }
    //     dispatch(virtualTicketUpdate(json, currentTicket));
    //     dispatch(refreshSelectedOdds(json));
    //   })
    //   .catch((error) => {
    //     console.error(OP, error);
    //   });
  };

export const deleteCurrentVirtualTicket =
  (): StoreTypes.AppThunk => (dispatch, getState) => {
    const {
      system: { sessionId },
      virtualTicket: { currentTicket },
    } = getState();
    const OP = 'deleteCurrentVirtualTicket';
    document.dispatchEvent(new Event('click'));
    dispatch(virtualTicketUpdate(undefined, currentTicket));
    dispatch(updateSelectedOdds([], []));
  };

// OFFER UPDATE

export const updateOffer = (): StoreTypes.AppThunk => (dispatch, getState) => {
  console.debug('Thunk updateOffer');
  dispatch(terminateSession());
  dispatch(setInitialDate());
  const retry = () => {
    console.debug('Thunk updateOffer fetching..');
    // performance.mark('downloadOffer');
    return fetchInitialData().then(
      (data) => {
        // console.log(
        //   performance.measure('thunk download offer', 'downloadOffer')
        // );
        // performance.mark('resetOffer');

        dispatch(resetOffer(data));
        // console.log(performance.measure('thunk reset offer', 'resetOffer'));
        // performance.mark('updateToday');
        dispatch(updateToday());
        // console.log(performance.measure('thunk updateToday', 'updateToday'));
        dispatch(setInitialDate());
        console.debug('Thunk updateOffer complete');
      },
      (error) => {
        console.error('error fetching data ', error);
        setTimeout(() => dispatch(retry), 2000);
      }
    );
  };
  setTimeout(() => retry(), 1200);
};

export const startNewSession =
  (): StoreTypes.AppThunk => (dispatch, getState) => {
    const {
      system: { sessionId, isCheckingTicket },
      virtualTicket: { currentTicket },
    } = getState();

    if (isCheckingTicket) {
      dispatch(updateIsCheckingTicket(false));
    }
    dispatch(resetAllTickets());
    // dispatch(virtualTicketSessionStart());
    dispatch(startSession());
    // dispatch(setInitialDate());
    console.debug('Thunk startNewSession started');
  };

// clear selected bets

export const showScanTicketThunk =
  (): StoreTypes.AppThunk => (dispatch, getState) => {
    const {
      system: { sessionId },
    } = getState();

    if (sessionId === undefined) {
      dispatch(startSession());
    }
    console.log(`showScanTicketThunk`);
    console.log(`toggleCheckTicket `);
    dispatch(updateIsCheckingTicket(false));
    dispatch(updateIsCheckingTicket(true));
  };

const refreshSelectedOdds =
  (data: FEG_Betslip): StoreTypes.AppThunk =>
  (dispatch, getState) => {
    let betIds: string[] = [];
    let oids: string[] = [];
    let betslipId = getState().virtualTicket.betslip?.betslipId;
    if (betslipId === undefined || betslipId !== data.betslipId) {
      console.warn('refreshSelectedOdds not for current ticket');
      return;
    }

    for (let leg of data.legs) {
      betIds.push(leg.selection.market.marketId);
      oids.push(leg.selection.selection.selectionId);
    }

    dispatch(updateSelectedOdds(betIds, oids));
  };
