import React from 'react';
import { useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';

import { changeCurrentTicket } from '../../store/thunks';
import {
  showPreparedTickets,
  toggleTicketExpanded,
} from '../../store/ticket/ticket-actions';
import { TicketMessage } from '../../store/ticket/ticket-types';

const VirtualTicketTabs: React.FC<VirtualTicketTabsReduxProps> = (props) => {
  const {
    currentTicket,
    isShowingPreparedTickets,
    showPreparedTickets,
  } = props;
  const dispatch = useDispatch();

  const ticketHandlersStatusClass = [1, 2, 3, 4].map((nr) => {
    return nr === currentTicket && !isShowingPreparedTickets
      ? 'bg-yellow'
      : ' bg-white-gradient';
  });

  const changeTicketHandler = (e: React.SyntheticEvent<HTMLElement>) => {
    const ticket = Number(e.currentTarget.dataset.ticket);
    console.log('changeTicket , ', ticket);
    dispatch(changeCurrentTicket(ticket));
  };

  return (
    <div className='ml-24 flex h-44 lh-44 text-13 semibold'>
      {[1, 2, 3, 4].map((nr) => (
        <div
          key={nr}
          data-ticket={nr}
          onClick={changeTicketHandler}
          className={`${ticketHandlersStatusClass[nr - 1]
            } inline-block w-56 text-center mr-1 rounded-t`}
        >
          {nr}
        </div>
      ))}
      <div
        className={`${isShowingPreparedTickets ? 'bg-yellow' : 'bg-white-gradient'
          } inline-block px-24 text-center mr-1 rounded-t`}
        onClick={showPreparedTickets}
      >
        Pregătite
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const {
    currentTicket,
    isExpanded,
    isShowingPreparedTickets,
    betslip,
  } = state.virtualTicket;

  // const {
  //   stake.stakeTotal as miza,
  //   totalOdd,
  //   totalOddString,
  //   ticket_mode,
  //   ticket_mode_info,
  //   messages,
  //
  //   castig,
  // } =betslip;
  //
  const miza = betslip?.toPay;
  const totalOdd = betslip?.totalOdds;
  const totalOddString = `${totalOdd?.toFixed((2))}`;
  const ticket_mode = betslip?.betslipType;
  //FIXME: TicketModeInfo
  const ticket_mode_info = "TICKET_MODE_INFO"
  const messages: TicketMessage[] = [];
  const castig = betslip?.potentialWinning
  return {
    miza,
    totalOdd,
    totalOddString,
    ticket_mode,
    ticket_mode_info,
    messages,
    currentTicket,
    castig,
    isShowingPreparedTickets,
    isExpanded,
  };
};

const mapDispatchActions = (dispatch: Dispatch) => {
  return {
    dispatch,
    toggleExpanded: () => dispatch(toggleTicketExpanded()),
    showPreparedTickets: () => dispatch(showPreparedTickets()),
  };
};
const virtualTicketTabsConnector = connect(mapStateToProps, mapDispatchActions);

type VirtualTicketTabsReduxProps = ConnectedProps<
  typeof virtualTicketTabsConnector
>;

const ConnectedVirtualTicketTabs = virtualTicketTabsConnector(
  VirtualTicketTabs
);
export default ConnectedVirtualTicketTabs;
