import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useSWR from 'swr';
import { RootState } from '../../store';
import { ReactComponent as PaperCutDecoration } from '../../img/paper-cut-22px.svg';
import VirtualTicketBetRow from '../VirtualTicket/VirtualTicketBetRow';
import { wsMessage } from '../../store/system/actions';
import { getTicketsAPI } from '../../hardware';
import { BetStatus } from '../../data/Ticket';
import { DateTime } from 'luxon';
import CheckTicketBetRow from './CheckTicketBetRow';

const fetcher = (
  url: string,
  ticketId: string,
  protectionCode: string,
  sessionId: string,
  appId: string
) =>
  fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ticketId, protectionCode, sessionId, appId }),
  })
    .then((res) => res.json())
    .then((json) => {
      console.log('Fetch ticket results', ' json: ', json);
      if (json['result'] === '0') {
        throw Error('Ticket not found');
      }
      if (json['result'] !== '1') {
        throw Error(`Unexpected result: ${json['result']}`);
      }
      const data = json['details']; //['ticket'] as VirtualTicketState;
      return data;
    });

const ModeInfo: { [key: string]: string } = {
  SOLO: 'Simplu',
  AKO: 'Multiplu',
  GROUP_COMBI: 'Maxicombi',
  LEG_COMBI: 'Sistem',
};

const StatusInfo: {
  [key: string]: { status: BetStatus; label: string; color: string };
} = {
  WINNING: { status: BetStatus.Winning, label: 'Câștigător', color: '#19FF84' },
  LOSING: {
    status: BetStatus.NonWinning,
    label: 'Necâștigător',
    color: '#FFA89F',
  },
  UNKNOWN: {
    status: BetStatus.Unknown,
    label: 'În desfășurare',
    color: '#ffd639',
  },
  UNRESOLVED: {
    status: BetStatus.Unresolved,
    label: 'Indecis',
    color: '#ffd639',
  },
  RETURNED: {
    status: BetStatus.Returned,
    label: 'Returnat',
    color: '#19FF84',
  },
  CANCELLED: {
    status: BetStatus.Cancelled,
    label: 'Stornat',
    color: '#d06406',
  },
  VOIDED: {
    status: BetStatus.Voided,
    label: 'Anulat',
    color: '#d06406',
  },
};
interface TicketDetailsV2Bonus {
  id: string;
  name: string;
  category: string;
  type: string;
  state: string;
  bonusFlowType: string;
  bonusValue: number;
  bonusPercentage?: number;
}
interface TicketDetailsV2Group {
  name: string;
  banker: boolean;
  oddsPlaced: number;
  legs: TicketDetailsV2Leg[];
}
interface TicketDetailsV2Leg {
  legId: number;
  product: string; //SPORTSBOOK_PREMATCH | LOTO
  mirrorType: string;
  oddsPlaced: number;
  startTime: string;
  sportId: string;
  sportCategory: string;
  sportName: string;
  tournamentName: string;
  fixtureName: string;
  fixtureId: string;
  marketName: string;
  selectionName: string;
  selectionId: number;
  marketOutcomeResult: string;
  result: string;
  state?: string;
  mainMarketId: string;
  type: string;
  fixed: boolean;
}
interface TicketDetailsV2 {
  type: string;
  product: string;
  number: string;
  token: string;
  shortCode: string;
  placementDetailsTime: string;
  result: string;
  groups: TicketDetailsV2Group[];
  legs: TicketDetailsV2Leg[];
  bonuses: TicketDetailsV2Bonus[];
  bonusesSummary: {
    bonusAmount: number;
    bonusPercentage: number;
  };
  lotteryInfo?: {
    id: number;
    name: string;
    icon: string;
    drawTime: string;
  };
  currency: string;
  timeSettled: string;
  payoutDetailsTime: string;
  placementDetailsHandlingFeeAmount: number;
  placementDetailsHandlingFeeFeeRate: number;
  oddsTotal: number;
  stakeStakeTotal: number;
  placementDetailsPaid: number;
  payoutDetailsGrossWinning: number;
  taxAmount: number;
  potentialWinning: number;
  potentialWinningAlt: number;
  potentialGrossWinning: number;
  payoutDetailsWinning: number;
}

interface TicketDetail {
  date: string;
  mode: string;
  status: string;
  time: string;
  ticketID: string;
  kind: string;
  summary: {
    shortcode: string;
    'total-odds-value': string;
    'total-price': number;
    'total-prize': number;
    'total-bet-value': number;
    'handling-charge-value': number;
    'extra-charge-value': number;
  };
  preview: {
    group: Array<{
      group: string;
      count: number;
      odds: number;
      'is-banker': boolean;
      'bet-type': Array<{
        action: string;
        actionID: string;
        active: boolean;
        datetime: string;
        icon: string;
        infoID: number;
        itemID: number;
        name: string;
        result: string;
        score: string;
        sport: string;
        sportID: number;
        subname: string;
        odds: {
          oddsID: number;
          name: string;
          value: string;
          blocked?: boolean;
          frozen?: boolean;
          result?: string;
        };
      }>;
    }>;
  };
}

const TicketResults: React.FC<{
  ticketId: string;
  protectionCode: string;
  onError: () => void;
}> = (props) => {
  const { ticketId, protectionCode, onError } = props;
  const sessionId = useSelector<RootState>((state) => state.system.sessionId);
  const appId = useSelector<RootState>((state) => state.system.uuid);
  const isTouchScreen = useSelector<RootState>(
    (state) => state.system.isTouchscreen
  );
  const dispatch = useDispatch();
  const { data, error } = useSWR(
    [
      `${getTicketsAPI()}/ticket/check_details`,
      ticketId,
      protectionCode,
      sessionId,
      appId,
    ],
    fetcher,
    {
      revalidateOnFocus: false,
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        console.log('error retry! ', error);
        if (`${error}` === 'Error: Ticket not found') {
          console.log('should stop retrying.');
          return;
        }
        // Only retry up to 10 times.
        if (retryCount >= 10) return;

        // Retry after 5 seconds.
        setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 5000);
      },
    }
  );

  useEffect(() => {
    if (data || error) {
      dispatch(
        wsMessage({
          appId,
          sessionId,
          isTouchscreen: false,
          rx_action: {
            type: 'CHECK_TICKET',
            code: `${ticketId}-${protectionCode}`,
            error: error,
          },
          dt: 0,
        })
      );
    }
  }, [dispatch, appId, sessionId, ticketId, protectionCode, error, data]);

  if (error)
    return (
      <div className='w-screen h-screen text-white text-20 semibold flex flex-col items-center content-center screen-padding box-border'>
        <div>Biletul tău nu a fost găsit. Te rugăm să încerci din nou.</div>
        <div className='text-center mt-32'>
          <div
            className='inline-block h-44 lh-44 w-100 text-center text-13 text-dark bold bg-yellow rounded-4'
            onClick={onError}
            style={{ width: '160px' }}
          >
            OK
          </div>
        </div>
      </div>
    );
  if (!data)
    return (
      <div className='w-screen h-screen text-white  text-20 semibold flex flex-row items-center content-center screen-padding box-border'>
        <div>Se caută biletul..</div>
      </div>
    );

  const ticket: TicketDetailsV2 = data;

  const modeName: string = ModeInfo[ticket.type as string] ?? '*';
  const statusLabel = StatusInfo[ticket.result] ?? 'În desfășurare';

  let ticketPlacementDate = DateTime.fromISO(ticket.placementDetailsTime);
  let ticketDate = ticketPlacementDate.toFormat('yyyy-LL-dd');
  let ticketTime = ticketPlacementDate.toFormat('HH:mm');
  let groups: TicketDetailsV2Group[] =
    ticket.groups.length > 0
      ? ticket.groups
      : [
          {
            name: ' ',
            banker: false,
            oddsPlaced: ticket.oddsTotal,
            legs: ticket.legs,
          },
        ];

  // render data
  return (
    <div className='w-screen h-screen text-white flex flex-row items-center content-center screen-padding box-border'>
      <div className='flex flex-col h-full ' style={{ width: 420 }}>
        <h1 className='text-24 bold'>Verifică bilet</h1>
        <h2 className='text-12 semibold'>
          Această verificare nu reprezintă omologarea oficială a Biletului!
        </h2>
        <div className='' style={{ height: 14 }}>
          <PaperCutDecoration />
        </div>
        <div className='bg-white text-gray-100 text-14 p-32 flex-grow'>
          <div className='flex space-between lh-32 items-center mb-32'>
            <div className='inline-block text-24 bold'>{ticket.shortCode}</div>
            <div
              className='inline-block text-14 semibold px-12 rounded-17'
              style={{ backgroundColor: statusLabel.color }}
            >
              {statusLabel.label}
            </div>
          </div>
          <div className='text-right py-16 border-solid border-y-2 border-black'>
            <b>{modeName}</b>
          </div>
          <div className='h-48 lh-48 flex space-between border-b-dashed-1'>
            <b>Cota totală</b>
            <span>{Number(ticket.oddsTotal).toFixed(2)}</span>
          </div>
          <div className='h-48 lh-48 flex space-between border-b-dashed-1'>
            <b>Miza</b>
            <span>{Number(ticket.stakeStakeTotal).toFixed(2)}</span>
          </div>
          <div className='h-48 lh-48 flex space-between border-b-dashed-1'>
            <b>Comision</b>
            <span>
              {Number(ticket.placementDetailsHandlingFeeAmount).toFixed(2)}
            </span>
          </div>
          <div className='h-48 lh-48 flex space-between'>
            <b>De plată</b>
            <span>{Number(ticket.placementDetailsPaid).toFixed(2)}</span>
          </div>
          <div className='h-48 lh-48 flex space-between border-solid border-y-2 border-black'>
            <b>Câștiguri</b>
            <span>{Number(ticket.payoutDetailsWinning).toFixed(2)}</span>
          </div>
          {/* <div className='h-48 lh-48 flex space-between'>
            <b>Taxă suplimentară</b>
            <span>
              {Number(ticket.summary['extra-charge-value']).toFixed(2)}
            </span>
          </div> */}

          <div className='inline-block flex-grow flex-col'>&nbsp;</div>
          <div className='h-48 lh-48 flex space-between'>
            <span>{ticket.number}</span>
            <span>
              {ticketDate}&nbsp;{ticketTime}
            </span>
          </div>
        </div>
        <div className='' style={{ height: 14, transform: 'rotate(180deg)' }}>
          <PaperCutDecoration />
        </div>
      </div>
      <div
        className='overflow-y-scroll ml-24 items-start h-full p-t-24 box-border'
        style={{ paddingTop: 64 }}
      >
        {ticket.product === 'LOTO' &&
          groups.map((g) => (
            <div key={g.name}>
              {ticket.type === 'GROUP_COMBI' && (
                <div key={g.name} className='mt-24 checkticket-row'>
                  <div
                    className='h-36 lh-36 text-11 text-white semibold flex mb-1'
                    style={{ marginRight: 54 }}
                  >
                    <div className='inline-flex space-between flex-grow bg-gray-gradient rounded-t mr-1 px-12 box-border'>
                      <span>
                        GRUP <span className='text-yellow'>{g.name}</span>
                      </span>
                      <span>
                        {g.legs.length}{' '}
                        {g.legs.length !== 1 ? 'Pariuri' : 'Pariu'}
                      </span>
                    </div>
                    <div
                      className='inline-flex space-between bg-gray-gradient rounded-t pl-12 box-border'
                      style={{ width: 119 }}
                    >
                      <span>Cota</span>
                      <span className='text-yellow w-58 text-center'>
                        {g.oddsPlaced.toFixed(2)}
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {g.legs.map((b, bindex) => (
                <CheckTicketBetRow
                  key={b.legId}
                  editable={false}
                  roundedTop={true}
                  code={`${b.fixtureId}`}
                  name={b.fixtureName}
                  title={b.fixtureName}
                  id={`${b.selectionId}`}
                  otherOdds={[]}
                  // icon={`icon-sport-${b.sportCategory}`}
                  icon='icon-sport-lotto'
                  mode={ticket.type === 'LEG_COMBI' ? 'SYSTEM' : ticket.type}
                  index={`${bindex}`}
                  matchId=''
                  sport={b.sportName}
                  sportId={`${b.sportId}`}
                  competition={b.tournamentName}
                  competitionId={b.tournamentName}
                  fixed={ticket.type === 'LEG_COMBI' && b.fixed === true}
                  odd={{ name: b.selectionName, value: `${b.oddsPlaced}` }}
                  description={b.marketName}
                  groups={[]}
                  class=''
                  extraClassName='checkticket-row'
                  status={
                    StatusInfo[b.result]?.status ?? StatusInfo['UNKNOWN'].status
                  }
                />
              ))}
            </div>
          ))}

        {ticket.product === 'SPORTSBOOK_PREMATCH' &&
          groups.map((g) => (
            <div key={g.name}>
              {ticket.type === 'GROUP_COMBI' && (
                <div key={g.name} className='mt-24 checkticket-row'>
                  <div
                    className='h-36 lh-36 text-11 text-white semibold flex mb-1'
                    style={{ marginRight: 54 }}
                  >
                    <div className='inline-flex space-between flex-grow bg-gray-gradient rounded-t mr-1 px-12 box-border'>
                      <span>
                        GRUP <span className='text-yellow'>{g.name}</span>
                      </span>
                      <span>
                        {g.legs.length}{' '}
                        {g.legs.length !== 1 ? 'Pariuri' : 'Pariu'}
                      </span>
                    </div>
                    <div
                      className='inline-flex space-between bg-gray-gradient rounded-t pl-12 box-border'
                      style={{ width: 119 }}
                    >
                      <span>Cota</span>
                      <span className='text-yellow w-58 text-center'>
                        {g.oddsPlaced.toFixed(2)}
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {g.legs.map((b, bindex) => (
                <CheckTicketBetRow
                  key={b.legId}
                  editable={false}
                  roundedTop={true}
                  code={`${b.fixtureId}`}
                  name={b.fixtureName}
                  title={b.fixtureName}
                  id={`${b.selectionId}`}
                  otherOdds={[]}
                  icon={`icon-sport-${b.sportCategory}`}
                  mode={ticket.type === 'LEG_COMBI' ? 'SYSTEM' : ticket.type}
                  index={`${bindex}`}
                  matchId=''
                  sport={b.sportName}
                  sportId={`${b.sportId}`}
                  competition={b.tournamentName}
                  competitionId={b.tournamentName}
                  fixed={ticket.type === 'LEG_COMBI' && b.fixed === true}
                  odd={{ name: b.selectionName, value: `${b.oddsPlaced}` }}
                  description={b.marketName}
                  groups={[]}
                  class=''
                  extraClassName='checkticket-row'
                  status={
                    StatusInfo[b.result]?.status ?? StatusInfo['UNKNOWN'].status
                  }
                />
              ))}
            </div>
          ))}
      </div>
    </div>
  );
};
export default TicketResults;
